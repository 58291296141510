import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import {
	Alert,
	Form,
	LinkedPhoneNumberInput,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';
import { clientInfoRequestForm } from '@sporkbytes/api-client/PublicContacts';

import FormHelperText from '../FormHelperText';
import withRecaptcha from '../withRecaptcha';

import axios from '../../services/axios';
import { sendEvent } from '../../services/analytics';

const validationSchema = Yup.object().shape({
	firstName: Yup.string().required('Please enter your first name.'),
	lastName: Yup.string().required('Please enter your last name.'),
	emailAddress: Yup.string()
		.email('Please enter a valid email address.')
		.required('Please enter your email address.'),
	phoneNumber: Yup.string(),
	comments: Yup.string().required(
		"Please let us know what you're looking for so we can better assist you."
	),
});

/** A short form for requesting information about Spork Bytes */
const ClientInfoRequestForm = ({ dispatch, getRecaptchaToken }) => {
	const [error, setError] = useState();
	const onSubmit = async (
		{ firstName, lastName, emailAddress, phoneNumber, comments },
		{ setSubmitting }
	) => {
		try {
			setSubmitting(true);
			setError();

			const recaptchaResponse = await getRecaptchaToken();

			await clientInfoRequestForm(axios, {
				firstName,
				lastName,
				emailAddress,
				phoneNumber,
				comments,
				recaptchaResponse,
			});

			dispatch({
				type: 'setStateValue',
				payload: { key: 'formSubmitted', value: true },
			});

			sendEvent({
				action: 'Submit Get Info Form',
				label: 'Success',
			});
		} catch (error) {
			sendEvent({
				action: 'Submit Get Info Form',
				label: 'Error',
			});

			setError(
				'There was an error processing your request. Please try again.'
			);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<Form
			onSubmit={onSubmit}
			onCancel={null}
			initialValues={{
				firstName: '',
				lastName: '',
				emailAddress: '',
				phoneNumber: '',
				comments: '',
			}}
			validationSchema={validationSchema}
			submitButtonContent="Submit"
		>
			{() => (
				<>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<FormHelperText>
								Tell us a little bit about yourself and we'll
								reach out to you.
							</FormHelperText>
						</Grid>
						<Grid item xs={12} sm={6}>
							<LinkedTextInput name="firstName" autoFocus />
						</Grid>
						<Grid item xs={12} sm={6}>
							<LinkedTextInput name="lastName" />
						</Grid>
						<Grid item xs={12} sm={6}>
							<LinkedTextInput
								name="emailAddress"
								placeholder="jill@example.com"
								type="email"
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<LinkedPhoneNumberInput name="phoneNumber" />
						</Grid>
						<Grid item xs={12}>
							<LinkedTextInput
								name="comments"
								label="Comments"
								multiline={true}
								rows={3}
							/>
						</Grid>
					</Grid>
					{error && <Alert type="error">{error}</Alert>}
				</>
			)}
		</Form>
	);
};

ClientInfoRequestForm.propTypes = {
	state: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
};

export default withRecaptcha(ClientInfoRequestForm);
